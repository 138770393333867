<template>
  <b-row>
    <b-col>
      <kyc-user-data />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import KycUserData from './KycUserData.vue'

export default {
  components: {
    KycUserData,
    BRow,
    BCol,
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
