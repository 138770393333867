<template>
  <b-card>
    <h3>Verificacion KYC basica</h3>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">

          <b-row>
            <b-col sm="12">
              <b-form-input
                v-model="kycAddresValue"
                placeholder="Enter Email"
                type="text"
              />
              <br>
              <b-row>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="kycAddress"
                  >
                    Buscar
                  </b-button> &nbsp;
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="approveKyc"
                  >
                    Aprobar
                  </b-button> &nbsp;
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secundary"
                    @click="resetForm"
                  >
                    Reset
                  </b-button>
                </b-col>
                <b-col class="text-center">
                  Usar Listado no activos
                  <b-row>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="backList"
                      >
                        Atras
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="nextList"
                      >
                        Siguiente
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <br><br>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Aprobado/No Aprobado"
                label-for="approved"
              >
                Active: {{ kycObject.accountActive }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Nombre"
                label-for="name"
              >
                {{ kycObject.name }} {{ kycObject.lastName }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Birth Date"
                label-for="birth-date"
              >
                {{ kycObject.dateBirth }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Email"
                label-for="email"
              >
                {{ kycObject.email }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Ciudad"
                label-for="city"
              >
                {{ kycObject.city }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Nacionalidad"
                label-for="nationality"
              >
                {{ kycObject.nationality }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Estado"
                label-for="state"
              >
                {{ kycObject.state }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Telefono"
                label-for="phone"
              >
                {{ kycObject.phone }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Direccion"
                label-for="Address"
              >
                <b>{{ kycObject.address1 }}</b>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Billetera"
                label-for="wallet"
              >
                {{ kycObject.wallet }}
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6">
          <b-row>
            <b-col lg="12">
              <b-form-group
                label="Frontal Image"
                label-for="frontal-image"
              >
                {{ this.kycObject.sourceDocument1 }}
                <b-img
                  :src="kycObject.kycUrl1"
                  fluid
                  alt="KYC Image"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Back Image"
                label-for="back-image"
              >
                {{ this.kycObject.sourceDocument2 }}
                <b-img
                  :src="kycObject.kycUrl2"
                  fluid
                  alt="KYC Image"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BImg, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BImg,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      kycUrl: '',
      kycObject: {},
      kycAddresValue: '',
      kycList: [],
      listKycIndex: -1,
      blockchainId: null,
    }
  },
  created() {
    this.getWithOutVerify()
    this.blockchainId = this.$sessionData.getCurrentNetworkData().id
  },
  methods: {
    resetForm() {
      this.kycObject = {}
      this.kycList = []
      this.getWithOutVerify()
    },
    getWithOutVerify() {
      this.$http.defaults.headers['hey!'] = 'jg3*'
      this.$http.get(`/kyc?nid=${this.blockchainId}`)
        .then(kycDataList => {
          // debugger
          this.kycList = kycDataList.data
        })
        .catch(() => false)
    },
    nextList() {
      this.listKycIndex += 1
      this.kycObject = this.kycList[this.listKycIndex]
      this.kycObject.kycUrl1 = ''
      this.kycObject.kycUrl2 = ''
      this.kycObject.kycUrl1 = `https://e-groweed.com:3800/api/v1/image/${this.kycObject.sourceDocument1}`
      this.kycObject.kycUrl2 = `https://e-groweed.com:3800/api/v1/image/${this.kycObject.sourceDocument2}`
    },
    approveKyc() {
      return new Promise((resolve, reject) => {
        /* eslint no-underscore-dangle: 0 */
        if (this.kycObject._id) {
          this.$http.defaults.headers['hey!'] = 'jg3*'
          this.$http.post(`/kyc/${this.kycObject._id}?nid=${this.blockchainId.id}`, {
            accountActive: '1',
          })
            .then(() => {
              alert('Approved!')
              this.kycObject.accountActive = '1'
              resolve()
            })
            .catch(e => {
              alert('Error')
              reject(e)
            })
        }
      })
    },
    async kycAddress() {
      return new Promise((resolve, reject) => {
        if (this.kycAddresValue !== '') {
          this.$http.defaults.headers['hey!'] = 'jg3*'
          this.$http.get(`/kyc/${this.kycAddresValue}/email?nid=${this.blockchainId.id}`)
            .then(kycData => {
              this.kycObject = kycData.data.kyc
              this.kycObject.kycUrl1 = `https://e-groweed.com:3800/api/v1/image/${this.kycObject.sourceDocument1}`
              this.kycObject.kycUrl2 = `https://e-groweed.com:3800/api/v1/image/${this.kycObject.sourceDocument2}`
              resolve()
            })
            .catch(e => {
              reject(e)
            })
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
